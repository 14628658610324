
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * ColVis styles
 */
div.ColVis {
	float: right;
	margin-bottom: 1em;
}

button.ColVis_Button,
ul.ColVis_collection li {
	position: relative;
	float: left;
	margin-right: 3px;
	padding: 5px 8px;
	border: 1px solid #999;
	cursor: pointer;
	*cursor: hand;
	font-size: 0.88em;
	color: black !important;
	white-space: nowrap;

	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
	    -ms-border-radius: 2px;
	     -o-border-radius: 2px;
	        border-radius: 2px;

	-webkit-box-shadow: 1px 1px 3px #ccc;
	   -moz-box-shadow: 1px 1px 3px #ccc;
	    -ms-box-shadow: 1px 1px 3px #ccc;
	     -o-box-shadow: 1px 1px 3px #ccc;
	        box-shadow: 1px 1px 3px #ccc;

	/* Generated by http://www.colorzilla.com/gradient-editor/ */
	background: #ffffff; /* Old browsers */
	background: -webkit-linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* Chrome10+,Safari5.1+ */
	background:    -moz-linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* FF3.6+ */
	background:     -ms-linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* IE10+ */
	background:      -o-linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* Opera 11.10+ */
	background:         linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f9f9f9',GradientType=0 ); /* IE6-9 */
}

.ColVis_Button:hover,
ul.ColVis_collection li:hover {
	border: 1px solid #666;
	text-decoration: none !important;

	-webkit-box-shadow: 1px 1px 3px #999;
	   -moz-box-shadow: 1px 1px 3px #999;
	    -ms-box-shadow: 1px 1px 3px #999;
	     -o-box-shadow: 1px 1px 3px #999;
	        box-shadow: 1px 1px 3px #999;

	background: #f3f3f3; /* Old browsers */
	background: -webkit-linear-gradient(top, #f3f3f3 0%,#e2e2e2 89%,#f4f4f4 100%); /* Chrome10+,Safari5.1+ */
	background:    -moz-linear-gradient(top, #f3f3f3 0%,#e2e2e2 89%,#f4f4f4 100%); /* FF3.6+ */
	background:     -ms-linear-gradient(top, #f3f3f3 0%,#e2e2e2 89%,#f4f4f4 100%); /* IE10+ */
	background:      -o-linear-gradient(top, #f3f3f3 0%,#e2e2e2 89%,#f4f4f4 100%); /* Opera 11.10+ */
	background:         linear-gradient(top, #f3f3f3 0%,#e2e2e2 89%,#f4f4f4 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f3f3', endColorstr='#f4f4f4',GradientType=0 ); /* IE6-9 */
}

button.ColVis_Button {
	height: 30px;
	padding: 3px 8px;
}

button.ColVis_Button::-moz-focus-inner { 
	border: none !important;
	padding: 0;
}

button.ColVis_Button:active { 
	outline: none;
}


div.ColVis_collectionBackground {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: black;
	z-index: 1100;
}

ul.ColVis_collection {
	list-style: none;
	width: 150px;
	padding: 8px 8px 4px 8px;
	margin: 0;
	border: 1px solid #ccc;
	border: 1px solid rgba( 0, 0, 0, 0.4 );
	background-color: #f3f3f3;
	background-color: rgba( 255, 255, 255, 0.3 );
	overflow: hidden;
	z-index: 2002;

	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
	    -ms-border-radius: 5px;
	     -o-border-radius: 5px;
	        border-radius: 5px;
	
	-webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
	   -moz-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
	    -ms-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
	     -o-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
	        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

ul.ColVis_collection li {
	position: relative;
	height: auto;
	left: 0;
	right: 0;
	padding: 0.5em;

	display: block;
	float: none;
	margin-bottom: 4px;
	
	-webkit-box-shadow: 1px 1px 3px #999;
	   -moz-box-shadow: 1px 1px 3px #999;
	    -ms-box-shadow: 1px 1px 3px #999;
	     -o-box-shadow: 1px 1px 3px #999;
	        box-shadow: 1px 1px 3px #999;
}

ul.ColVis_collection li {
	text-align: left;
}

ul.ColVis_collection li.ColVis_Button:hover {
	border: 1px solid #999;
	background-color: #f0f0f0;
}

ul.ColVis_collection li span {
	display: inline-block;
	padding-left: 0.5em;
	cursor: pointer;
}


ul.ColVis_collection li.ColVis_Special {
	border-color: #555;
	background: rgb(237,237,237); /* Old browsers */
	background: -webkit-linear-gradient(top, rgba(237,237,237,1) 0%,rgba(214,214,214,1) 77%,rgba(232,232,232,1) 100%); /* Chrome10+,Safari5.1+ */
	background:    -moz-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(214,214,214,1) 77%, rgba(232,232,232,1) 100%); /* FF3.6+ */
	background:     -ms-linear-gradient(top, rgba(237,237,237,1) 0%,rgba(214,214,214,1) 77%,rgba(232,232,232,1) 100%); /* IE10+ */
	background:      -o-linear-gradient(top, rgba(237,237,237,1) 0%,rgba(214,214,214,1) 77%,rgba(232,232,232,1) 100%); /* Opera 11.10+ */
	background:         linear-gradient(to bottom, rgba(237,237,237,1) 0%,rgba(214,214,214,1) 77%,rgba(232,232,232,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#e8e8e8',GradientType=0 ); /* IE6-9 */
}

ul.ColVis_collection li.ColVis_Special:hover {
	background: #e2e2e2; /* Old browsers */
	background: -webkit-linear-gradient(top, #d0d0d0 0%,#d5d5d5 89%,#e2e2e2 100%); /* Chrome10+,Safari5.1+ */
	background:    -moz-linear-gradient(top, #d0d0d0 0%,#d5d5d5 89%,#e2e2e2 100%); /* FF3.6+ */
	background:     -ms-linear-gradient(top, #d0d0d0 0%,#d5d5d5 89%,#e2e2e2 100%); /* IE10+ */
	background:      -o-linear-gradient(top, #d0d0d0 0%,#d5d5d5 89%,#e2e2e2 100%); /* Opera 11.10+ */
	background:         linear-gradient(top, #d0d0d0 0%,#d5d5d5 89%,#e2e2e2 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f3f3', endColorstr='#e2e2e2',GradientType=0 ); /* IE6-9 */
}


span.ColVis_radio {
	display: inline-block;
	width: 20px;
}

div.ColVis_catcher {
	position: absolute;
	z-index: 1101;
}

.disabled {
	color: #999;
}


